import React from 'react'
import Layout from '../../../templates/Master'
import Media from '../../../modules/Media'
import { Box } from '@mui/material'
import LineBreak from '../../../components/general/LineBreak'
import Heading from '../../../modules/Heading'
import Content from '../../../modules/Content'
import Form from '../../../modules/Form'
import Carousel from '../../../modules/Carousel'
import Share from '../../../modules/Share'
import Table from '../../../modules/Table'

const BidPayEthPage = () => {
    return (
        <Layout>
            <Media media = {[
                "comicconnect_mark_zuckerberg_rookie_card_front_top_cb8d6fa4c2.jpg",
                "comic-connect-1992-mark-zuckerberg-rookie-card-poster-frame-NFT.jpg",
                "comicconnect-mark-zuckerberg-rookie-card-front-middle.jpg" ]} />
            <Box textAlign="center" my={8}>
                <Heading as='h1' content='Next Minimum ETH Bid Increments for the NFT auction' />
            </Box>
            <LineBreak short/>
            <Table type='nft'/>
            <LineBreak short/>
            <Content
                alignText="center"
                content="<h2>Paying with Ether (ETH) for the NFT/Digital asset</h2><p>We will send an invoice for the NFT to the successful bidder after the auction has ended. The winner will pay in Ether (ETH). We will charge an additional 1% of the total amount of the invoice. After payment is confirmed, the digital asset will then be transferred from Allie to the auction winner.</p><p>The winner will also be subject to any gas fees associated with conducting the transaction on the Ethereum network.</p><p>We will facilitate minting of, and transfer to the auction winner of, the 1/1 Authentic 1992 Mark Zuckerberg Rookie Card NFT through one of the world’s leading and most reliable marketplaces. Keep an eye out, more details to follow.</p>" />
            <LineBreak short/>
            <Content
                alignText="center"
                content="<h3>Ethereum Gas Fees</h3><p>The Ethereum network is one of the most popular blockchain networks for running decentralized applications (dApps) and smart contracts. A gas fee is an amount of Ether (Eth) required for an Ethereum network user to conduct a transaction on the network. Gas fees are used to compensate Ethereum miners for their work in verifying transactions and securing the network. Gas fees also help keep the network from becoming bogged down by malicious users spamming the network.</p><p>The auction winner will pay Ethereum gas fees. These fees vary because the formula used to calculate them is dynamic, and the Ethereum network calculates its base fee based on network activity.</p><p>You can track Gas fees on this<a className='orange' target='_blank' href='https://www.blocknative.com/gas-estimator'>Gas Estimator</a>.</p>" />
            <LineBreak />
            <Box textAlign="center" mt={8}>
                <Heading as='h3' variant='h4' content='Sign up to receive auction updates!' width={['auto', 'auto', 'auto', 'auto', 800]} />
            </Box>
            <Content
                alignText="center"
                content="<p>Don't miss this historic event! Please sign up here to stay up to date and receive sneak previews!</p>"
                width={['auto', 'auto', 'auto', 'auto', 800]}/>
            <Form />
            <LineBreak />
            <Box textAlign="center" my={8}>
                <Heading as='h2' content='Photos and Video of the Original 1992 Mark Zuckerberg Baseball Card & NFT' />
            </Box>
            <Carousel video media={[
                "comic-connect-1992-mark-zuckerberg-rookie-card-poster-frame-NFT.jpg",
                "comicconnect-mark-zuckerberg-rookie-card-front-middle.jpg",
                "comicconnect-mark-zuckerberg-rookie-card-day-camp-1992-with-card.jpg",
                "comicconnect-mark-zuckerberg-rookie-card-day-camp-1992-bear.jpg",
                "comicconnect_mark_zuckerberg_rookie_card_day_camp_counselor_c92b07296f.jpg",
                "comicconnect_mark_zuckerberg_rookie_card_irvin_simon_c85982ae3a.jpg",
                "comicconnect_mark_zuckerberg_rookie_card_day_camp_1993_a9968c0984.jpg" ]}/>
            <LineBreak />
            <LineBreak />
            <Box textAlign="center" my={8}>
                <Heading as='h2' width={['auto', 'auto', 'auto', 'auto', 500]} content='Tell your friends about the Mark Zuckerberg baseball card & NFT auction!' />
            </Box>
            <Box textAlign="center" mb={5}>
                <Share theme='orange'/>
            </Box>
        </Layout>
    )
}


export default BidPayEthPage
